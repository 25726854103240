<template>
<div class="login-page">
    <div class="container d-flex align-items-center position-relative py-5">
        <div class="card shadow-sm w-100 rounded overflow-hidden bg-none">
            <div class="card-body p-0">
                <div class="row gx-0 align-items-stretch">
                    <!-- Logo & Information Panel-->
                    <div class="col-lg-6">
                        <div class="info d-flex justify-content-center flex-column p-4">
                            <div class="py-1">
                                <h1>Bestellung abgeschlossen</h1>
                            </div>
                        </div>
                    </div>
                    <!-- Form Panel    -->
                    <div class="col-lg-6 bg-white">
                        <div class="d-flex align-items-center px-4 px-lg-5 h-100">
                            <div>
                                <h3>Bestellungseingang</h3>
                                <p class="lead">Vielen Dank für Ihre Bestellung!</p>
                                <p>Ihre Bestellung ist bei uns eingegangen und wird nun gepr&uuml;ft. Sobald die Pr&uuml;fung abgeschlossen ist, erhalten Sie eine Auftragsbest&auml;tigung als E-Mail.</p>
                                <router-link class="btn btn-primary" :to="{ name: 'Home'}">Weiter einkaufen</router-link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "ShopCheckoutAbgeschlossen",
};
</script>
